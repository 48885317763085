import { combineReducers } from '@reduxjs/toolkit';
import { ActionFromReducersMapObject, CombinedState, Reducer, StateFromReducersMapObject } from "redux";
import { actionApi } from 'src/services/actionApi';
import { adminApi } from 'src/services/adminApi';
import { anonymousApi } from 'src/services/anonymousApi';
import { appApi } from 'src/services/appApi';
import { authApi } from 'src/services/authApi';
import { baseApi } from 'src/services/baseApi';
import { companyApi } from 'src/services/companyApi';
import { documentApi } from 'src/services/documentApi';
import { entityApi } from 'src/services/entityApi';
import { learningCenterApi } from 'src/services/learningCenterApi';
import { lenderShoeBoxApi } from 'src/services/lenderShoeBoxApi';
import { loanApi } from 'src/services/loanApi';
import { messageApi } from 'src/services/messageApi';
import { noteApi } from 'src/services/noteApi';
import { packageApi } from 'src/services/packageApi';
import { taskApi } from 'src/services/taskApi';
import { userApi } from 'src/services/userApi';
import { venturesApi } from 'src/services/venturesApi';
import { reducer as adminReducer } from 'src/slices/admin';
import { adminUiSlice } from 'src/slices/admin-ui';
import { consolidatedLenderSlice } from 'src/slices/consolidated-lender';
import { reducer as documentsReducer } from 'src/slices/documents';
import { reducer as elementV2Reducer } from 'src/slices/elementv2';
import { entitySlice } from 'src/slices/entity';
import { reducer as formElementReducer } from 'src/slices/form-element';
import { learningCenterSlice } from 'src/slices/learning-center';
import { lenderSlice } from 'src/slices/lender';
import { licenseKeysSlice } from 'src/slices/license-keys';
import { reducer as listsReducer } from 'src/slices/lists';
import { reducer as loanReducer } from 'src/slices/loan';
import { loansManagerSlice } from 'src/slices/loan-manager';
import { reducer as loanStagesReducer } from 'src/slices/loan-stages';
import { reducer as loanTemplateReducer } from 'src/slices/loan-template';
import { reducer as personReducer } from 'src/slices/person';
import { profileSlice } from 'src/slices/profile';
import { reducer as searchReducer } from 'src/slices/search';
import { shoeBoxSlice } from 'src/slices/shoebox';
import { systemSlice } from 'src/slices/system';
import { reducer as taskReducer } from 'src/slices/task';
import { templateV2Slice } from 'src/slices/templatev2';
import { reducer as templateV2TypeReducer } from 'src/slices/templatev2-types';
import { timezoneSlice } from 'src/slices/timezone';
import { reducer as uiReducer } from 'src/slices/ui';
import { userSlice } from 'src/slices/user';
import { reducer as viewReducer } from 'src/slices/view';
import { zipFileJobSlice } from 'src/slices/zip-jobs';

import { reducer as settingsReducer } from '../slices/settings';

export const reducers = {
  [userSlice.name]: userSlice.reducer,
  view: viewReducer,
  person: personReducer,
  loan: loanReducer,
  lists: listsReducer,
  documents: documentsReducer,
  ui: uiReducer,
  formElement: formElementReducer,
  search: searchReducer,
  admin: adminReducer,
  settings: settingsReducer,
  task: taskReducer,
  loanTemplate: loanTemplateReducer,
  loanStages: loanStagesReducer,
  elementv2: elementV2Reducer,
  templatev2Type: templateV2TypeReducer,
  [profileSlice.name]: profileSlice.reducer, // [profileSlice.name] is the same as ['profile']
  [timezoneSlice.name]: timezoneSlice.reducer, // [timezoneSlice.name] is the same as ['timezone'
  [lenderSlice.name]: lenderSlice.reducer,
  [templateV2Slice.name]: templateV2Slice.reducer,
  [consolidatedLenderSlice.name]: consolidatedLenderSlice.reducer,
  [shoeBoxSlice.name]: shoeBoxSlice.reducer,
  [entitySlice.name]: entitySlice.reducer,
  [learningCenterSlice.name]: learningCenterSlice.reducer,
  [adminUiSlice.name]: adminUiSlice.reducer, // [adminUiSlice.name] is the same as ['adminUi'
  [loansManagerSlice.name]: loansManagerSlice.reducer,
  [systemSlice.name]: systemSlice.reducer,
  [licenseKeysSlice.name]: licenseKeysSlice.reducer,
  [zipFileJobSlice.name]: zipFileJobSlice.reducer,
  [baseApi.reducerPath]: baseApi.reducer,
};

const combinedReducer = combineReducers(reducers);

export const rootReducer = (state, action): Reducer<
  CombinedState<StateFromReducersMapObject<typeof reducers>>,
  ActionFromReducersMapObject<typeof reducers>
> => {
  if (action.type === 'auth/logout') { // check for action type
    state = undefined;
  }
  // @ts-ignore
  return combinedReducer(state, action);
};

