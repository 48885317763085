export const getSubdomainFromUrl = (url: string): string => {
    // Check if the URL is valid.
    if (!url.startsWith("http://") && !url.startsWith("https://")) {
        return "";
    }

    // Split the URL into its hostname and protocol parts.
    const hostnameAndProtocol = url.split("://");

    // Get the hostname.
    const hostname = hostnameAndProtocol[1];

    // Split the hostname into its subdomain and domain parts.
    const subdomainAndDomain = hostname.split(".");

    // Return the subdomain.
    return subdomainAndDomain[0];
}