
export const UsStates = {
    "AK": {
        "ISOAbbreviation": "US-AK",
        "unabbreviated": "Alaska"
    },
    "AL": {
        "ISOAbbreviation": "US-AL",
        "unabbreviated": "Alabama"
    },
    "AR": {
        "ISOAbbreviation": "US-AR",
        "unabbreviated": "Arkansas"
    },
    "AZ": {
        "ISOAbbreviation": "US-AZ",
        "unabbreviated": "Arizona"
    },
    "CA": {
        "ISOAbbreviation": "US-CA",
        "unabbreviated": "California"
    },
    "CO": {
        "ISOAbbreviation": "US-CO",
        "unabbreviated": "Colorado"
    },
    "CT": {
        "ISOAbbreviation": "US-CT",
        "unabbreviated": "Connecticut"
    },
    "DC": {
        "ISOAbbreviation": "US-DC",
        "unabbreviated": "District of Columbia"
    },
    "DE": {
        "ISOAbbreviation": "US-DE",
        "unabbreviated": "Delaware"
    },
    "FL": {
        "ISOAbbreviation": "US-FL",
        "unabbreviated": "Florida"
    },
    "GA": {
        "ISOAbbreviation": "US-GA",
        "unabbreviated": "Georgia"
    },
    "HI": {
        "ISOAbbreviation": "US-HI",
        "unabbreviated": "Hawaii"
    },
    "IA": {
        "ISOAbbreviation": "US-IA",
        "unabbreviated": "Iowa"
    },
    "ID": {
        "ISOAbbreviation": "US-ID",
        "unabbreviated": "Idaho"
    },
    "IL": {
        "ISOAbbreviation": "US-IL",
        "unabbreviated": "Illinois"
    },
    "IN": {
        "ISOAbbreviation": "US-IN",
        "unabbreviated": "Indiana"
    },
    "KS": {
        "ISOAbbreviation": "US-KS",
        "unabbreviated": "Kansas"
    },
    "KY": {
        "ISOAbbreviation": "US-KY",
        "unabbreviated": "Kentucky"
    },
    "LA": {
        "ISOAbbreviation": "US-LA",
        "unabbreviated": "Louisiana"
    },
    "MA": {
        "ISOAbbreviation": "US-MA",
        "unabbreviated": "Massachusetts"
    },
    "MD": {
        "ISOAbbreviation": "US-MD",
        "unabbreviated": "Maryland"
    },
    "ME": {
        "ISOAbbreviation": "US-ME",
        "unabbreviated": "Maine"
    },
    "MI": {
        "ISOAbbreviation": "US-MI",
        "unabbreviated": "Michigan"
    },
    "MN": {
        "ISOAbbreviation": "US-MN",
        "unabbreviated": "Minnesota"
    },
    "MO": {
        "ISOAbbreviation": "US-MO",
        "unabbreviated": "Missouri"
    },
    "MS": {
        "ISOAbbreviation": "US-MS",
        "unabbreviated": "Mississippi"
    },
    "MT": {
        "ISOAbbreviation": "US-MT",
        "unabbreviated": "Montana"
    },
    "NC": {
        "ISOAbbreviation": "US-NC",
        "unabbreviated": "North Carolina"
    },
    "ND": {
        "ISOAbbreviation": "US-ND",
        "unabbreviated": "North Dakota"
    },
    "NE": {
        "ISOAbbreviation": "US-NE",
        "unabbreviated": "Nebraska"
    },
    "NH": {
        "ISOAbbreviation": "US-NH",
        "unabbreviated": "New Hampshire"
    },
    "NJ": {
        "ISOAbbreviation": "US-NJ",
        "unabbreviated": "New Jersey"
    },
    "NM": {
        "ISOAbbreviation": "US-NM",
        "unabbreviated": "New Mexico"
    },
    "NV": {
        "ISOAbbreviation": "US-NV",
        "unabbreviated": "Nevada"
    },
    "NY": {
        "ISOAbbreviation": "US-NY",
        "unabbreviated": "New York"
    },
    "OH": {
        "ISOAbbreviation": "US-OH",
        "unabbreviated": "Ohio"
    },
    "OK": {
        "ISOAbbreviation": "US-OK",
        "unabbreviated": "Oklahoma"
    },
    "OR": {
        "ISOAbbreviation": "US-OR",
        "unabbreviated": "Oregon"
    },
    "PA": {
        "ISOAbbreviation": "US-PA",
        "unabbreviated": "Pennsylvania"
    },
    "PR": {
        "ISOAbbreviation": "US-PR",
        "unabbreviated": "Puerto Rico"
    },
    "RI": {
        "ISOAbbreviation": "US-RI",
        "unabbreviated": "Rhode Island"
    },
    "SC": {
        "ISOAbbreviation": "US-SC",
        "unabbreviated": "South Carolina"
    },
    "SD": {
        "ISOAbbreviation": "US-SD",
        "unabbreviated": "South Dakota"
    },
    "TN": {
        "ISOAbbreviation": "US-TN",
        "unabbreviated": "Tennessee"
    },
    "TX": {
        "ISOAbbreviation": "US-TX",
        "unabbreviated": "Texas"
    },
    "UT": {
        "ISOAbbreviation": "US-UT",
        "unabbreviated": "Utah"
    },
    "VA": {
        "ISOAbbreviation": "US-VA",
        "unabbreviated": "Virginia"
    },
    "VT": {
        "ISOAbbreviation": "US-VT",
        "unabbreviated": "Vermont"
    },
    "WA": {
        "ISOAbbreviation": "US-WA",
        "unabbreviated": "Washington"
    },
    "WI": {
        "ISOAbbreviation": "US-WI",
        "unabbreviated": "Wisconsin"
    },
    "WV": {
        "ISOAbbreviation": "US-WV",
        "unabbreviated": "West Virginia"
    },
    "WY": {
        "ISOAbbreviation": "US-WY",
        "unabbreviated": "Wyoming"
    }
} as const;

export const Route = {
    HOME: '/',
    LOGIN: '/auth/login',
    LOGOUT: '/api/auth/logout',
    BORROWER_PORTAL: '/portal',
    BORROWER_PORTAL_LENDER: '/portal/[lenderId]',
    BORROWER_PORTAL_EXIT: '/portal/[lenderId]/exit',
    SIGNUP: '/auth/signup',
    FORGOT_PASSWORD: '/auth/forgot-password',
    NEW_PASSWORD: '/auth/new-password',
    PASSWORD_UPDATED: '/auth/password-updated',
    LENDER_HOME: '/lender',
    PRINCIPAL_HOME: '/applicant',
    ANONYMOUS_HOME: '/anonymous',
    ARCHIVE_HOME: '/loans/phases/archive',
    LEAD_HOME: '/loans/phases/lead',
    CALENDAR_HOME: '/calendar',
    COMMUNICATIONS_HOME: '/communications',
    LOANS_TEMPLATES: '/loans/templates',
    ANNOTATOR: '/annotator',
    LOANS_PHASE_CATEGORY: '/loans/phases/[phase]',
    ANALYTICS_HOME: '/charts',
    LOANS_ORIGINATION_HOME: '/loans/phases/origination',
    LOANS_SERVICING_HOME: '/loans/phases/servicing',
    LOANS_PORTFOLIO_HOME: '/loans/phases/portfolio',
    LOANS_LEAD_HOME: '/loans/phases/lead',
    SINGLE_LOAN: '/loans/[loanId]',
    SINGLE_LOAN_MESSAGES: '/loans/[loanId]/messages',
    SINGLE_LOAN_TASKS: '/loans/[loanId]/tasks',
    SINGLE_LOAN_TASKS_TYPE: '/loans/[loanId]/tasks/[type]',
    LOAN_COMPANY_EDIT: '/loans/[loanId]/company/[companyId]/edit',
    LOAN_TRUST_EDIT: '/loans/[loanId]/trust/[trustId]/edit',
    LOAN_ASSET_EDIT: '/loans/[loanId]/asset/[assetId]/edit',
    SINGLE_LOAN_TEMPLATE: '/loans/templates/[templateId]',
    CREATE_LOAN: '/loans/create',
    ADMIN_LABELS: '/admin/labels',
    ADMIN_LEARNING_CENTER: '/admin/learning-center',
    ADMIN_LEARNING_CENTER_CREATE: '/admin/learning-center/create',
    ADMIN_LEARNING_CENTER_EDIT: '/admin/learning-center/[id]/edit',
    ADMIN_MAINTENANCE: '/admin/maintenance',
    ADMIN_PAGE: '/admin',
    CREATE_LOAN_TEMPLATE: '/loans/templates/create',
    EDIT_SINGLE_LOAN: '/loans/[loanId]/edit',
    EDIT_SINGLE_LOAN_TEMPLATE: '/loans/templates/[templateId]/edit',
    CREATE_LOAN_PRINCIPAL: '/loans/[loanId]/persons/create',
    CREATE_LOAN_ENTITY: '/loans/[loanId]/entity/create',
    CREATE_LOAN_COMPANY: '/loans/[loanId]/company/create',
    CREATE_LOAN_TRUST: '/loans/[loanId]/trust/create',
    CREATE_LOAN_APPLICANT: '/loans/[loanId]/applicant/create',
    CREATE_LOAN_ASSET: '/loans/[loanId]/asset/create',
    // v2 templates
    LIST_V2_TEMPLATE: '/v2/templates',
    EDIT_V2_TEMPLATE: '/v2/templates/[templateId]/edit',
    CREATE_V2_TEMPLATE: '/v2/templates/create',
    SINGLE_V2_TEMPLATE: '/v2/templates/[templateId]',
    EDIT_LOAN_PRINCIPAL: '/loans/[loanId]/persons/[personId]/edit',
    ADD_LOAN_LENDER_ROLE: '/loans/[loanId]/lender/create',
    EDIT_LOAN_LENDER_ROLE: '/loans/[loanId]/lender/[roleId]/edit',
    // LEARNING CENTER
    LEARNING_CENTER: '/v2/learning-center',
    LEARNING_CENTER_SINGLE_ITEM: '/v2/learning-center/entry/[slug]',
    LEARNING_CENTER_SINGLE_CATEGORY: '/v2/learning-center/category/[slug]',
    LANDING_PAGE: '/landing',
} as const;

export const GuestRoutes = [
    Route.SIGNUP,
    Route.LOGIN,
    Route.FORGOT_PASSWORD,
    Route.NEW_PASSWORD,
    Route.LANDING_PAGE,
];

export const KeyboardKey = {
    ENTER: 'Enter',
    ESCAPE: 'Escape',
    CTRL: 'Control',
    SHIFT: 'Shift',
} as const;

export const HttpHeader = {
    MY_SHERPAS_MESSAGES: 'my-sherpas-message',
    MY_SHERPAS_LOCKED_USER: 'sherpas-user-locked',
    MY_SHERPAS_PASSWORD_EXPIRED: 'sherpas-user-password-expired',
    MY_SHERPAS_REMAINING_LOCKED_SECONDS: 'sherpas-user-locked-seconds-remaining',
    MY_SHERPAS_ONE_LOGIN_ATTEMPT_LEFT: 'sherpas-user-one-login-attempt',
};

export const OOPS_MESSAGE = 'Server error happened when executing operation.';

export const POLLING_INTERVAL = 10000;

export const DEFAULT_TOAST_DURATION = 10000;

export const MAX_TOAST_DURATION = 20000;

export const UNAUTHORIZED_ERROR_STATUS = 'UNAUTHORIZED';

export const API_VERSION = '/api';

export const ActionStatus = {
    idle: 'idle',
    success: 'success',
    loading: 'loading',
    error: 'error'
} as const;


export const DASHBOARD_SIDEBAR_PORTAL_ID = 'left-sidebar-portal';

export const TaskGroup = {
    AssignQuestionTask: 'AssignQuestionTask',
    AnswerQuestionTask: 'AnswerQuestionTask',
    AssignSigneeTask: 'AssignSigneeDocumentTask',
    FillFormTask: 'FillFormTask',
    ApproveAnswerTask: 'ApproveAnswerTask',
    ShoeBoxTask: 'ShoeBoxTask',
    FillTask: 'FillDocumentTask',
    FillDocumentTaskDto: 'FillDocumentTaskDto',
    SignTask: 'SignTask',
} as const;

export const LoanSidebar = {
    TASKS_LIST_UPLOAD: TaskGroup.AnswerQuestionTask,
    TASKS_LIST_FILL_FORM: TaskGroup.FillFormTask,
    TASKS_LIST_ASSIGN_SIGNEE: TaskGroup.AssignSigneeTask,
    TASKS_LIST_APPROVE: TaskGroup.ApproveAnswerTask,
    TASKS_LIST_SHOE_BOX: TaskGroup.ShoeBoxTask,
    TASKS_LIST_SIGN: TaskGroup.SignTask,
    TASKS_LIST_FILL: TaskGroup.FillTask,
    TASKS_LIST_FILL_2: TaskGroup.FillDocumentTaskDto,
} as const;



export const FormElementNavigator = {
    Simple: 'Simple',
    Map: 'TreeMap',
} as const;


export const LoanOverviewView = {
    Tasks: 'Tasks',
    Sections: 'Sections',
} as const;


export const PageLabel = {
    Leads: 'Leads',
    Home: 'Origination',
    Origination: 'Origination',
    MyLoans: 'My Loans',
    Portfolio: 'Portfolio',
    Archive: 'Archive',
    Labels: 'Labels',
    Onboarding: 'Onboarding',
    LearningCenter: 'Learning Center',
    Maintenance: "Maintenance Window",

} as const;

export const CustomEventType = {
    SignatureFieldSelected: 'mySherpasSignatureFieldSelected',
    AssignSignatureFieldToUser: 'mySherpasAssignSignatureFieldToUser',
    SavePdfFile: 'mySherpasSavePdfFile',
} as const;


export const EMPTY_OBJECT = {} as const;

export const PhoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

export const EINRegExp = /^\d{2}-\d{7}$/
// regex to validate website url
export const WebsiteRegExp = /^(https?:\/\/)?((([a-z\d]([a-z\d-]*[a-z\d])*)\.)+[a-z]{2,}|((\d{1,3}\.){3}\d{1,3}))(\:\d+)?(\/[-a-z\d%_.~+]*)*(\?[;&a-z\d%_.~+=-]*)?(\#[-a-z\d_]*)?$/i
export const JestTestId = {
    UploadProgressContainer: 'upload-progress-container',
    FileDropTarget: 'form-element-dropzone',
    PreviewDropTargetCamScanButton: 'preview-drop-target-cam-scan-button',
    FormElementCamScanDialogContainer: 'form-element-cam-scan-dialog-container',
    SpreadSheetViewer: 'spread-sheet-viewer',
}

export enum KeyStorage {
    KanbanSetting = 'kanbanSetting',
    PackageFormElementsVisibility = 'packageFormElementsVisibility',
    TimezoneMode = 'timezoneMode',
    SendAutomaticPublicMessage = 'sendAutomaticPublicMessage',
    TableColumns = 'tableColumns',
    LenderShoeBoxTeamFilterList = 'lenderShoeBoxTeamFilter',
    PackageColumnWidth = 'packageColumnWidth',
    PinnedBorrowerPortalLenders = 'pinnedBorrowerPortalLenders',
    HiddenBorrowerPortalLenders = 'hiddenBorrowerPortalLenders',
    LoanViewType = 'loanViewType',
    DoNotShowTourAgain = 'doNotShowTourAgain',
    CompletedProductTourSteps = 'completedProductTourSteps',
    LoanOverviewAccordions = 'loanOverviewAccordions',
    OneDriveDefaultEditPlatform = 'oneDriveDefaultEditPlatform',
}

export enum OrderDirection {
    Asc = 'asc',
    Desc = 'desc',
}

export const QUERY_EXPAND_FILE_PREVIEW = 'expandedPreview';
export const MOBILE_SUBDOMAIN = 'mobile';

export const MAIN_CONTENT_FOOTER_ELEMENT_ID = 'main-content-footer';

export const ADMIN_QUERY_ADD_USER_MODAL = 'addUserModal';

export const ADMIN_QUERY_ADD_USER_MODAL_EMPLOYEE_ID = 'addUserModalEmployeeId';

export const DIALOG_CONTAINER_ID = 'shadcdn-dialog-container';

export const MOBILE_BREAKPOINT = 640;

export const MOBILE_MEDIA_QUERY = `(max-width: ${MOBILE_BREAKPOINT}px)`;

export const DESKTOP_BREAKPOINT = 1024;

export const DESKTOP_MEDIA_QUERY = `(min-width: ${DESKTOP_BREAKPOINT}px)`;