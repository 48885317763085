
import { LoanEntityLabel } from "src/backend";

import { TKnowledgeBase } from "./formelement";

export const EntityLabel = {
    ['PRIMARY_OPERATION_COMPANY']: {
        short: "POC",
        long: "Primary Operating Company",
    },
    ['OPERATING_COMPANY']: {
        short: "OC",
        long: "Operating Company",
    },
    ['EPC']: {
        short: "EPC",
        long: "Eligible Passive Company",
    },
    ['AFFILIATE']: {
        short: "Affiliate",
        long: "Affiliate",
    },
    ['RELATED_NON_AFFILIATE']: {
        short: "RNA",
        long: "Related Non-Affiliate",
    },
    ['NOT_A_GUARANTOR']: {
        short: "NAG",
        long: "Not a Guarantor",
    },
    ['FRANCHISEE']: {
        short: "Franchisee",
        long: "Franchisee",
    },
    ['NEW']: {
        short: "New",
        long: "New",
    },
    ['PRIMARY_COLLATERAL']: {
        short: "Primary",
        long: "Primary",
    },
    ['SPOUSE']: {
        short: "Spouse",
        long: "Spouse",
    },
    ['GUARANTOR']: {
        short: "Guarantor",
        long: "Guarantor",
    },
    ['APPLICANT']: {
        short: "Borrower",
        long: "Borrower",
    },
    ['MEMBER']: {
        short: "Member",
        long: "Member",
    },
    ['SECURED_LIMITED']: {
        short: "Secured Limited",
        long: "Secured Limited",
    },
    ['SECURED_FULL']: {
        short: "Secured Full",
        long: "Secured Full",
    },
    ['UNSECURED_FULL']: {
        short: "Unsecured Full",
        long: "Unsecured Full",
    },
    ['UNSECURED_LIMITED']: {
        short: "Unsecured Limited",
        long: "Unsecured Limited",
    },
    ['NON_AFFILIATE']: {
        short: "Non-Affiliate",
        long: "Non-Affiliate",
    },
}

export const LoanCompanyLabels: LoanEntityLabel[] = [
    'AFFILIATE',
    'EPC',
    'OPERATING_COMPANY',
    'PRIMARY_OPERATION_COMPANY',
    'RELATED_NON_AFFILIATE',
]

export interface LoanEntity {
    id?: string;
    loanId?: string;
    name: string;
    createFromTemplateId: string;
    knowledgeBase?: TKnowledgeBase;
}

export enum GuaranteeType {
    NOT_A_GUARANTOR = 'NOT_A_GUARANTOR',
    UNSECURED_FULL = 'UNSECURED_FULL',
    SECURED_FULL = 'SECURED_FULL',
    SECURED_LIMITED = 'SECURED_LIMITED',
    UNSECURED_LIMITED = 'UNSECURED_LIMITED',
}