import NextRouter from 'next/router';
import { api } from "src/api";
import { isServer } from "src/utils";
import { getUrlWithoutProtocolAndPort } from "src/utils/url/get-url-without-protocol-and-port";

export const useSubdomainRedirect = () => {

    return { checkSubdomainValidity: subDomainFilter } as const;
};

const KNOWN_SUBDOMAINS = ['mobile']

const subDomainFilter = async () => {
    if (isServer) {
        return;
    }
    const host = window.location.host;
    const envVars = await api.getEnvVars();
    const installationUrl = envVars.SHERPAS_AI_INSTALLATIONROOTURL;
    // if we host is the same as the root url skip
    const hostUrl = getUrlWithoutProtocolAndPort(host);
    const rootUrl = getUrlWithoutProtocolAndPort(installationUrl);

    if (hostUrl === rootUrl) {
        return;
    }
    const subdomain = getSubdomain(host);
    // if subdomain is in the list of known subdomains, skip
    if (KNOWN_SUBDOMAINS.includes(subdomain)) {
        return;
    }
    // the subdomain is not known, get company details for it
    try {
        const result = await api.getCompanyDetails(subdomain);
        // if we don't have a company id
        // and we have a installation root url,
        // redirect to the landing page
        if (!result.id && installationUrl) {
            return NextRouter.replace(`${installationUrl}/landing`)
        }
    } catch (error) {
        // if request fails
        // and we have a installation root url,
        // redirect to landing page
        if (installationUrl) {
            return NextRouter.replace(`${installationUrl}/landing`)
        }
    }
};

const getSubdomain = (host: string) => {
    if (host.split('.').length === 1) {
        return null;
    }
    return host.split('.')[0];
}